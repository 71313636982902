import React from 'react';

import { Box, Grid, xcss } from '@atlaskit/primitives';

import { EndOfPageCard } from '@confluence/page-card/entry-points/EndOfPageCard';
import type { GlobalPageCard } from '@confluence/page-card';

export type EndOfPageRecGridProps = {
	pageCardData: GlobalPageCard[];
	pageRecStrategy?: string[][];
	entityId: string;
};

const TwoCardWrapperWidth = xcss({
	maxWidth: '480px',
});

const ThreeCardWrapperWidth = xcss({
	maxWidth: '320px',
});

const BigCardHeight = xcss({
	height: '128px',
});

const getColumnsBasedOnNumberOfCards = (totalCards: number) => {
	const twoColumns = '1fr 1fr';
	const threeColumns = 'repeat(auto-fit, minmax(320px, 1fr))';
	switch (totalCards) {
		case 1:
		case 2:
		case 4:
			return twoColumns;
		default:
			return threeColumns;
	}
};

const getCardStyles = (totalCards: number) => {
	let stylesArray;
	switch (totalCards) {
		case 1:
		case 2:
		case 4:
			stylesArray = [TwoCardWrapperWidth];
			break;
		default:
			stylesArray = [ThreeCardWrapperWidth];
			break;
	}
	stylesArray.push(BigCardHeight);
	return stylesArray;
};

export const EndOfPageRecGrid = ({
	pageCardData,
	pageRecStrategy,
	entityId,
}: EndOfPageRecGridProps) => {
	return (
		<Grid templateColumns={getColumnsBasedOnNumberOfCards(pageCardData.length)} gap="space.200">
			{pageCardData.map((node, i) => (
				<Box key={node.id} xcss={() => getCardStyles(pageCardData.length)}>
					<EndOfPageCard
						data={node}
						ranking={i}
						analyticsData={{
							source: 'endOfPageRecommendations',
							attributes: {
								strategy: pageRecStrategy ? pageRecStrategy[i % pageRecStrategy.length] : null,
								mainContentId: entityId,
							},
						}}
					/>
				</Box>
			))}
		</Grid>
	);
};
