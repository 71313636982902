import type { GlobalPageCard } from '@confluence/page-card';
import { transformGlobalContent } from '@confluence/page-card';

import type { EndOfPageRecommendationQuery_getRecommendedPages_recommendedPages as EndOfPageRecommendationData } from './__types__/EndOfPageRecommendationQuery';

export const transformEndOfPageRecContent = (
	data: EndOfPageRecommendationData,
	strategiesCounter,
): GlobalPageCard => {
	const pageCardData: GlobalPageCard = transformGlobalContent(data.content);

	pageCardData.likesCount = 0;
	pageCardData.commentsCount = 0;
	pageCardData.recommendationStrategy = chooseStrategy(data, strategiesCounter);
	return pageCardData;
};

/**
 * @param strategiesCounter is a hashmap that keeps track of how many times each strategy has been used
 * so that we can select the lowest occuring strategy on the next data point. This helps to reduce
 * monotony of strategies on the UI
 * @returns the selected strategy for each rec which will be applied to @function transformEndOfPageRecContent
 */
export const chooseStrategy = (data, strategiesCounter) => {
	const strategies = data.strategy;

	if (strategies.includes('CONTENT_TO_CONTENT')) {
		return 'CONTENT_TO_CONTENT';
	}

	const currentCounter = new Map();
	strategies.forEach((entry) => {
		const entryCount = strategiesCounter.get(entry) || 0;
		currentCounter.set(entry, entryCount);
	});
	const sortedCounter = new Map([...currentCounter.entries()].sort((a, b) => a[1] - b[1]));
	const strategy = [...sortedCounter.keys()][0];
	const count = strategiesCounter.get(strategy) || 0;
	strategiesCounter.set(strategy, count + 1);
	return strategy;
};
