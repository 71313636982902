import { useRef, useState, useEffect } from 'react';
import debounce from 'lodash/debounce';

const HOVER_DELAY_TIME = 300;
const CLOSE_DELAY_TIME = 400;

export const useMultiHoverPopupCard = () => {
	const [isOpen, setIsOpen] = useState(false);
	const isMouseInsideRef = useRef(false);

	const onMouseLeaveDebounce = debounce(() => {
		if (!isMouseInsideRef.current) {
			setIsOpen(false);
		}
	}, CLOSE_DELAY_TIME);

	const onMouseEnterDebounce = debounce(() => {
		if (isMouseInsideRef.current) {
			setIsOpen(true);
		}
	}, HOVER_DELAY_TIME);

	const onMouseEnter = () => {
		isMouseInsideRef.current = true;
		onMouseEnterDebounce();
	};

	const onMouseLeave = () => {
		isMouseInsideRef.current = false;
		onMouseLeaveDebounce();
	};

	useEffect(() => {
		return () => {
			onMouseLeaveDebounce.cancel();
		};
	}, [onMouseLeaveDebounce]);

	return {
		isOpen,
		onMouseEnter,
		onMouseLeave,
	};
};
