import type { FC } from 'react';
import React from 'react';

import type { PageCardContextProviderProps } from './PageCardContextProvider';
import { EndOfPageView } from './Views';
import { AISnippetPageRecExperiment } from './Items/AISnippet/AISnippetPageRecExperiment';

type EndOfPageCardProps = Omit<PageCardContextProviderProps, 'appearance'>;

export const EndOfPageCard: FC<EndOfPageCardProps> = (wrapperProps) => {
	return (
		<AISnippetPageRecExperiment wrapperProps={wrapperProps}>
			{(isTriggerCard: boolean) => <EndOfPageView isTriggerCard={isTriggerCard} />}
		</AISnippetPageRecExperiment>
	);
};
