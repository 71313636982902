import { useQuery } from '@apollo/react-hooks';
import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';

import { Box } from '@atlaskit/primitives';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import {
	AIEventsInstrumentationProvider,
	type AIEventsInstrumentationConfig,
} from '@atlassian/ai-analytics';
import { usePopupDwellTime } from '@atlassian/popup-dwell-time';

import { Attribution, ErrorBoundary } from '@confluence/error-boundary';
import { FeatureCode } from '@confluence/feature-codes';

import { PopupView, PopupViewWithAISnippet } from '../Views';
import { PageCardContextProvider } from '../PageCardContextProvider';
import { AISnippetCard } from '../Items/AISnippet/AISnippetCard';
import type {
	SpaceHoverPageCardQuery as QueryData,
	SpaceHoverPageCardQueryVariables as QueryVars,
} from '../__types__/SpaceHoverPageCardQuery';
import { SpaceHoverPageCardQuery } from '../SpaceHoverPageCardQuery.graphql';
import { transformSpaceContent } from '../transformers';

export const PopupPageCardViewWithAISnippet = ({
	id,
	type,
	showAISnippets,
	onMouseEnter,
	onMouseLeave,
	update,
}: {
	id: string;
	type: string;
	showAISnippets: boolean;
	onMouseEnter: () => void;
	onMouseLeave: () => void;
	update: () => void;
}) => {
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const popupPageCardRef = useRef<HTMLDivElement | null>(null);

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { getDwellTime } = usePopupDwellTime(popupPageCardRef);

	const handleUpdate = useCallback(() => {
		if (!isLoading) {
			update();
		}
	}, [isLoading, update]);

	useEffect(() => {
		handleUpdate();
	}, [handleUpdate]);

	useEffect(() => {
		return () => {
			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					action: 'dwelled',
					actionSubject: 'spacePageCard',
					source: 'endOfPageRecommendations',
					actionSubjectId: 'endOfPageRecommendations',
					attributes: {
						contentId: id,
						dwellTime: getDwellTime(),
						isAiSnippet: showAISnippets,
					},
				},
			}).fire();
		};
		// we only want to run this effect on un-mount
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const { loading, data, error } = useQuery<QueryData, QueryVars>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		SpaceHoverPageCardQuery,
		{
			variables: {
				id,
			},
		},
	);

	const shouldRenderContent = useMemo(
		() => Boolean(!error && !loading && data?.singleContent),
		[error, loading, data],
	);

	const aiAnalyticsConfig: AIEventsInstrumentationConfig = useMemo(
		() => ({
			product: 'confluence',
			subproduct: 'confluence',
			source: 'confluencePage',
			aiFeatureName: 'aiSnippet',
			proactiveGeneratedAI: 1,
			userGeneratedAI: 0,
			additionalAttributes: {
				contentId: data?.singleContent?.id,
				contentType: data?.singleContent?.type,
				from: FeatureCode.EOP_REC_CARD,
			},
		}),
		[data?.singleContent?.id, data?.singleContent?.type],
	);

	return shouldRenderContent ? (
		<Box onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} ref={popupPageCardRef}>
			<ErrorBoundary attribution={Attribution.SMARTS}>
				<AIEventsInstrumentationProvider config={aiAnalyticsConfig}>
					<PageCardContextProvider
						data={transformSpaceContent(data?.singleContent)}
						appearance="popup-view"
						analyticsData={{
							attributes: {
								from: FeatureCode.EOP_REC_CARD,
							},
						}}
					>
						<AISnippetCard contentId={id} contentType={type}>
							{(snippetLoading, snippet) => {
								if (showAISnippets) {
									if (!snippetLoading) {
										setIsLoading(false);
									}
									return (
										<PopupViewWithAISnippet
											loading={!!snippetLoading}
											snippet={snippet}
											isEndOfPage
										/>
									);
								}
								return <PopupView />;
							}}
						</AISnippetCard>
					</PageCardContextProvider>
				</AIEventsInstrumentationProvider>
			</ErrorBoundary>
		</Box>
	) : null;
};
