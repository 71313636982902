import type { MutableRefObject } from 'react';
import { useRef, useEffect } from 'react';

export const useElementSeenCallback = (
	elementRef: MutableRefObject<HTMLDivElement | null>,
	onSeen: () => void,
	threshold = 1.0,
): void => {
	const observerRef = useRef<IntersectionObserver>();
	const hasIntersected = useRef(false);

	useEffect(() => {
		const disconnectObserver = () => {
			if (observerRef.current) {
				observerRef.current.disconnect();
				observerRef.current = undefined;
			}
		};

		const handleIntersect: IntersectionObserverCallback = (entries) => {
			if (!hasIntersected.current && entries[0].isIntersecting) {
				onSeen();
				hasIntersected.current = true;
				disconnectObserver();
			}
		};
		// eslint-disable-next-line
		observerRef.current = new IntersectionObserver(handleIntersect, {
			threshold,
		});

		if (elementRef.current) {
			observerRef.current.observe(elementRef.current);
		}

		return disconnectObserver;
	}, [onSeen, elementRef, threshold]);
};
