/* eslint-disable confluence-feature-gating/no-exposure-recommendation */
import React, { useCallback, forwardRef } from 'react';

import { Popup, type PopupComponentProps } from '@atlaskit/popup';
import { Box, xcss } from '@atlaskit/primitives';

import { manuallyLogExpExposure, expValEqualsNoExposure } from '@confluence/feature-experiments';
import { useSessionData } from '@confluence/session-data';

import { useMultiHoverPopupCard } from '../../hooks/useMultiHoverPopupCard';
import type { PageCardContextProviderProps } from '../../PageCardContextProvider';
import { PageCardContextProvider } from '../../PageCardContextProvider';
import { PopupPageCardViewWithAISnippet } from '../../Views/PopupPageCardViewWithAISnippet';

const pageRecCardContainer = xcss({
	height: '100%',
});

const popupCardStyles = xcss({
	borderRadius: 'border.radius.200',
});

export type AISnippetCardExperimentProps = {
	children: (isTriggerCard: boolean) => React.JSX.Element;
	wrapperProps: Omit<PageCardContextProviderProps, 'appearance'>;
};

export const AISnippetPageRecExperiment = ({
	children,
	wrapperProps,
}: AISnippetCardExperimentProps) => {
	const { isAdminHubAIEnabled } = useSessionData();

	const { isOpen, onMouseEnter, onMouseLeave } = useMultiHoverPopupCard();
	const { id, type } = wrapperProps.data;

	const onMouseEnterWrapper = useCallback(() => {
		if (isAdminHubAIEnabled) {
			manuallyLogExpExposure('confluence_ai_enabled_related_pages_snippets');
		} else {
			manuallyLogExpExposure('confluence_ai_disabled_related_pages_snippets');
		}

		onMouseEnter();
	}, [isAdminHubAIEnabled, onMouseEnter]);

	const triggerContent = useCallback(
		(triggerProps) => (
			<Box
				xcss={pageRecCardContainer}
				{...triggerProps}
				onMouseEnter={() => onMouseEnterWrapper()}
				onMouseLeave={onMouseLeave}
			>
				<PageCardContextProvider {...wrapperProps} appearance="end-of-page">
					{children(true)}
				</PageCardContextProvider>
			</Box>
		),
		[children, onMouseEnterWrapper, onMouseLeave, wrapperProps],
	);

	const PopupComponent = forwardRef<HTMLDivElement, PopupComponentProps>(
		({ 'data-testid': testId, ...props }, ref) => (
			<Box testId={testId} {...props} ref={ref} xcss={popupCardStyles} />
		),
	);

	const isInAIEnabledSnippetsExp = isAdminHubAIEnabled && type !== 'blogpost';

	const isAISnippetHoverEnabled =
		isInAIEnabledSnippetsExp &&
		expValEqualsNoExposure(
			'confluence_ai_enabled_related_pages_snippets',
			'cohort',
			'show_ai_snippet',
		);
	const isAIWithExcerptExp =
		isInAIEnabledSnippetsExp &&
		expValEqualsNoExposure(
			'confluence_ai_enabled_related_pages_snippets',
			'cohort',
			'show_snippet',
		);

	const isNonAIHoverEnabled =
		!isInAIEnabledSnippetsExp &&
		expValEqualsNoExposure(
			'confluence_ai_disabled_related_pages_snippets',
			'cohort',
			'show_snippet',
		);

	if (isAISnippetHoverEnabled || isAIWithExcerptExp || isNonAIHoverEnabled) {
		return (
			<Popup
				popupComponent={PopupComponent}
				trigger={(triggerProps) => triggerContent(triggerProps)}
				isOpen={isOpen}
				content={(props) => (
					<PopupPageCardViewWithAISnippet
						id={id}
						type={type}
						showAISnippets={isAISnippetHoverEnabled}
						onMouseEnter={() => onMouseEnterWrapper()}
						onMouseLeave={onMouseLeave}
						update={props.update}
					/>
				)}
				placement="top-start"
			/>
		);
	}

	return (
		<Box
			xcss={pageRecCardContainer}
			onMouseEnter={() => onMouseEnterWrapper()}
			onMouseLeave={onMouseLeave}
		>
			<PageCardContextProvider {...wrapperProps} appearance="end-of-page">
				{children(false)}
			</PageCardContextProvider>
		</Box>
	);
};
